import React from "react"



const SSS = () => (


    <div className="hiring-tabs">
        <input type="checkbox" name="dropdown" id="tab1" />
        <input type="checkbox" name="dropdown" id="tab2" />
        <input type="checkbox" name="dropdown" id="tab3" />
        <input type="checkbox" name="dropdown" id="tab4" />
        <input type="checkbox" name="dropdown" id="tab5" />

        <div className="tab" id="tab1sec">
            <label htmlFor="tab1"> </label>
            <h3>E-posta pazarlamada bültenleri ne sıklıkla göndermeliyim?</h3>
            <p>Bunun bir kuralı yoktur. Fakat en verimli sonuçlar için A/B testleri 
uygulayarak ne sıklıkla e-posta bülteni göndermenizin daha ideal 
olabileceği hakkında fikir sahibi olabilirsiniz.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab2sec">
            <label htmlFor="tab2"> </label>
            <h3>E-postaların spam kutusuna düşmemesi için ne yapmalıyım?</h3>
            <p>E-postaların spam haline gelmemesi için belli bazı noktalara dikkat 
etmelisiniz. Örneğin listenizdeki e-postaların izinli olması, abonelikten 
ayrılma seçeceğinin sunulması, e-postada yasaklı içeriklerin bulunmaması 
gerekiyor.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab3sec">
            <label htmlFor="tab3"> </label>
            <h3>E-posta pazarlama kampanyasının başarısı nasıl anlaşılır?</h3>
            <p>Markanız için önemli performans göstergeleri doğrultusunda 
kampanyanızın başarısını ölçümleyebilirsiniz. Bunun için hizmet 
sağlayıcısının sunduğu raporları inceleyebilirsiniz.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab4sec">
            <label htmlFor="tab4"> </label>
            <h3>E-posta listelerine abone olanların sayısı nasıl artırılabilir?</h3>
            <p>Abonelerin ilgi düzeyi, abone sayısından çok daha önemlidir. Dolayısıyla 
listenizdekilerin doğru kişiler olduğundan emin olmanız yararınıza 
olacaktır. Abone sayısını artırmak için ise websitenizde iyileştirmeler yapabilirsiniz.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
    
    </div>
)

export default SSS

import React from "react"
import rightArrow from "../assets/img/right-arrow.png";
import leftArrow from "../assets/img/left-arrow.png";
import peliunShan from "../assets/img/peliun-shan.png";
import mert from "../assets/img/mert-haciosmanoglu.png"
import omer from "../assets/img/burak-omer-ogulturk.png"
import mustafa from "../assets/img/mustafa-karaman.png"
import checkup2 from "../assets/img/liva-test.d.webp";
import checkup3 from "../assets/img/camtest.d.webp";
import checkup4 from "../assets/img/kuruyemis.d.webp";

const Testimonial = () => (

    <div className="our-customers py60 container">
        
        <div className="testimonials newsletter">
            <input type="radio" name="testimon" id="t1" />
            <input type="radio" name="testimon" id="t2" />
            <input type="radio" name="testimon" id="t3" />
            
            <div className="testimonial slex" id="one">
        <div className="tabs">
            <a href="/digital-danismanlik/" className="tab-link">Dijital Danışmanlık</a>
            <a href="/arama-motoru-optimizasyonu/" className="tab-link">SEO</a>
            <a href="/digital-ads/" className="tab-link">Digital Ads</a>
        </div>
                <div className="col-md-6 testt">
                <p id="customer1">“Dijitalde markamızın görünürlüğünü artırarak daha fazla satış elde etmemizi sağlamanın yanı sıra her konuda bizi de sürekli olarak aydınlatmaya çalıştığınız için teşekkür ederiz.”</p>
                <div className="customer ser">
                    <img src={mert} alt="peliun shan profile" loading="lazy"/>
                    <div className="customer-name">
                        <p>Mert Hacıosmanoğlu</p>
                        <span>Liva Pastacılık <br/>Yönetim Kurulu Üyesi</span>
                    </div>
                </div>
                </div>
                <div className="col-md-6 newsst">
                <img alt="alt text" src={checkup2} className="tettttt" loading="lazy"/>
                </div>
                <label htmlFor="t3" className="pre" > <img src={leftArrow} alt="left arrow" /></label>
                <label htmlFor="t2" className="nxt" > <img src={rightArrow} alt="right arrow" /></label>
            </div>
            <div className="testimonial slex" id="two">
            <div className="tabs">
            <a href="/digital-danismanlik/" className="tab-link">Dijital Danışmanlık</a>
            <a href="/arama-motoru-optimizasyonu/" className="tab-link">SEO</a>
            <a href="/digital-ads/" className="tab-link">Digital Ads</a>
        </div>
                <div className="col-md-6 testt">
                <p id="customer1">“Dijital pazarlama stratejisini tahminlerle değil, gerçek verilerden yola çıkarak belirleyen Flatart ekibine, en çok sonuç odaklı yaklaşımlarından ve markamızın büyümesindeki başarılarından dolayı teşekkür ediyorum.”</p>
                <div className="customer ser">
                    <img src={omer} alt="peliun shan profile"loading="lazy" />
                    <div className="customer-name">
                        <p>Burak Ömer Oğultürk</p>
                        <span>Çam Hotel CEO</span>
                    </div>
                </div>
                </div>
                <div className="col-md-6 newsst">
                <img alt="alt text" src={checkup3} className="tettttt" loading="lazy"/>
                </div>
                <label htmlFor="t1" className="pre" > <img src={leftArrow} alt="left arrow" /></label>
                <label htmlFor="t3" className="nxt" > <img src={rightArrow} alt="right arrow" /></label>
            </div>
            <div className="testimonial slex" id="three">
            <div className="tabs">
            <a href="/digital-danismanlik/" className="tab-link">Dijital Danışmanlık</a>
            <a href="/seo-ajansi/" className="tab-link">SEO</a>
            <a href="/digital-ads/" className="tab-link">Digital Ads</a>
        </div>
                <div className="col-md-6 testt">
                <p id="customer1">“Flatart ile ilgili beni en çok etkileyen şey, beklentilerimizi doğru anlamak için gösterdikleri gayret oldu. Bizi doğru anlamaları ise tabii ki kısa sürede istediğimiz sonuçlara ulaşmamızı sağladı.”</p>
                <div className="customer ser">
                    <img src={mustafa} alt="peliun shan profile" loading="lazy"/>
                    <div className="customer-name">
                        <p>Mustafa Karaman</p>
                        <span>Kahveci Hacıbaba CEO</span>
                    </div>
                </div>
                </div>
                <div className="col-md-6 newsst">
                <img alt="alt text" src={checkup4} className="tettttt" loading="lazy"/>
                </div>
                <label htmlFor="t2" className="pre" > <img src={leftArrow} alt="left arrow" /></label>
                <label htmlFor="t1" className="nxt" > <img src={rightArrow} alt="right arrow" /></label>
            </div>
        </div>
    </div>

)

export default Testimonial

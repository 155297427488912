import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Range from "../components/range"
import amazon from "../assets/img/amazon.png";

import eMarketing from "../assets/img/email-marketing.png"
import eManagement1 from "../assets/img/emanagement1.png"
import eManagement2 from "../assets/img/emanagement2.png"
import eManagement3 from "../assets/img/emanagement3.png"
import eManagement4 from "../assets/img/emanagement4.png"
import eManagement5 from "../assets/img/emanagement5.png"
import eManagement6 from "../assets/img/emanagement6.png"
import eManagement7 from "../assets/img/emanagement7.png"
import eManagement9 from "../assets/img/emanagement9.png"
import balance from "../assets/img/balance-time-money.png"
import achievement from "../assets/img/achievement-female.png"
import videoSearch from "../assets/img/video-search.png"
import cv from "../assets/img/cv-curriculum.png"
import maleMan from "../assets/img/male-man.png"
import business from "../assets/img/business-idea.png"
import lines from "../assets/img/backgr.png"

import Teams from "../components/teams/email-team"
import SSS from "../components/sss/email-sss"
import Testimonial from "../components/testimonial"
import checkModal from "../assets/img/check-modal.png";
import inbox from "../assets/img/inbox.png";

class EmailMarketing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');

        document.querySelector('#site').innerHTML = this.state.value;
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
            }
            else {
                document.querySelector('.status').innerHTML = "Bir hata oluştu.";


            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="E-posta Pazarlama" description="Hedef kitlenizle ilişkilerinizi geliştiriyor, ürün ve hizmetlerinizle ilgili bilgilendiriyor, müşteri sadakatini güçlendiriyoruz." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding">
                            <h1>E-posta Pazarlama</h1>
                            <span className="red-head">HİZMETLER</span>
                            <p>Hedef kitlenizle ilişkilerinizi geliştiriyor, ürün ve hizmetlerinizle ilgili bilgilendiriyor, müşteri sadakatini güçlendiriyoruz.</p>
                            <img alt="alt text" style={{ maxWidth: '250px' }} src={amazon}></img>
                            <img alt="alt text" style={{ maxWidth: '250px' }} src={inbox}></img>
                        </div>
                        <div className="col-md-6 e-marketin_right" ><img style={{ width: '90%', marginTop: 50 }} alt="alt text" src={eMarketing} /> </div>
                    </div>
                </div>     


                <div className="e-marketing container">
                    <div className="send-newsletter" style={{marginTop: 70}}>
                        <div style={{ textAlign: 'center' }}>
                            <h2>Bültenlerinizi 100 kat daha uygun fiyata <br />zahmetsizce gönderin.</h2>
                            <p>Ayda 100,000 üzerinde e-posta gönderimi yapıyorsanız, bu hizmetimiz tam size göre.</p>
                        </div>
                        <div className="row">
                            <Range />
                            <div className="col-md-6">
                                <div className="sendxs">
                                    <h3>E-posta Hizmet Yönetimi</h3>
                                    <p>Markanız adına hedef kitlenize ulaşıyor,<br /> pazarlama hedeflerinizi gerçekleştiriyoruz.</p>
                                    <ul className="email-services_list-e">
                                        <li><span className="tick"></span> E-posta Şablon Tasarımı & Geliştirilmesi </li>
                                        <li><span className="tick"></span> E-posta Pazarlama İçin Domain DNS Kurulumu </li>
                                        <li><span className="tick"></span> Sınırsız Subscriber Yönetimi </li>
                                        <li><span className="tick"></span> %99.7 Oranında Gelen Kutusuna Girme</li>
                                        <li><span className="tick"></span> Özel Pazarlama Uzmanı</li>
                                        <li><span className="tick"></span> KVKK uyumlu altyapı ve İYS entegrasyonu</li>
                                    </ul>
                                    <p className="risex">Aylık 90$’dan başlayan fiyatlarla.</p>
                                    <input type="text" className="pink-input" id="email-marketing-input" placeholder="E-posta adresinizi giriniz." value={this.state.value} onChange={this.handleChange} />
                                    <button className="red-button" id="email-marketing-button" onClick={(e) => this.showModal()}>Size özel teklif alın</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="email-management pt60">
                        <h2>E-posta yönetimi servisimiz <br />Sendy’nin özelliklerini keşfedin.</h2>
                        <div className="email-management_list pt60">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement1} />
                                    <h3>Gelişmiş Analiz ve Anlamlı Raporlar</h3>
                                    <p>Anlaşılır bir formata sahip raporda her kampanyanın ve otomatik yanıtlamaların sonuçlarını görebilirsiniz. Açılma, tıklama, hemen çıkma, şikayetler ile grafik ve veri kullanılarak görselleştirilen ülkeleri inceleyebilirsiniz. Yeniden hedefleme için abone segmentlerinizi dışarı aktarabilirsiniz.</p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement2} />
                                    <h3>E-posta Kampanya Otomatik Yanıtlama</h3>
                                    <p>E-posta almak için abone olan kişilerin markanızı zihinlerinde tutundurabilmek için onlara belirlediğiniz zaman aralıklarında otomatik olarak e-posta dizileri gönderebilirsiniz. </p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement3} />
                                    <h3>Gelişmiş Liste Segmentasyonu</h3>
                                    <p>Araştırma sonuçları, hedeflenen segmentlere e-posta gönderimi yapılmasının etkileşimi ve gelirleri %25 oranında artırdığını gösteriyor. Sendy, belirlediğiniz kriterlere göre dilediğiniz gibi segmentler yaratabilmenize olanak sağlar. </p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement4} />
                                    <h3>Gelişmiş Subscriber Yönetimi</h3>
                                    <p>Sendy sayesinde listeleri ve abonelikleri kolayca yönetebilirsiniz. E-posta abonelerini topluca alma/silme, single/double opt-in, abonelikten ayrılma onay sayfası, teşekkür/hoşçakal e-postaları, kullanıcı eklemek için API veya abonelik formu ve çok daha fazlasını Sendy ile kolayca gerçekleştirebilirsiniz. </p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement5} />
                                    <h3>Özelleştirilebilir Segmentasyon Alanları</h3>
                                    <p>“Ad-Soyad” ve “e-posta adresi”eden daha fazlasını kayıt altında tutmak için özel alanlar oluşturabilirsiniz. Abonelerinizi daha yakından tanımakla kalmaz, onlara daha kişiselleştirilmiş deneyimler sunmak için e-posta bültenlerinizde segmentasyon veya kişiselleştirme etiketleri kullanabilirsiniz.</p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement6} />
                                    <h3>Hemen Çıkma, Şikayet<br />& Unsubscribe Yönetimi</h3>
                                    <p>Sendy; e-posta bülteniniz gönderilir gönderilmez hemen ayrılma oranlarını, şikayetleri ve abonelikten ayrılmaları gerçek zamanlı ve otomatik olarak işlediğinden abone listenizi her zaman güncel tutabilirsiniz. </p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement6} />
                                    <h3>Bültenlerinizi 100 kat daha ucuza gönderin</h3>
                                    <p>Sendy, Amazon SES üzerinden 1$’a 10.000 e-posta göndermektedir. Artık e-posta kampanyalarına yüksek ücretler ödemekten kurtulabilir, aynı zamanda e-postaların son derece hızlı teslim edilmesinin de tadını çıkartabilirsiniz. </p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement7} />
                                    <h3>Üçüncü parti <br /> entegrasyonlar & Zapier</h3>
                                    <p>Sendy, üçüncü parti geliştiricileri sayesinde Wordpress, Magento, Joomla gibi popüler uygulamalarla entegre olabilmektedir. Ayrıca uygulama dizininde yer alan 1000’den fazla uygulama ile de entegrasyonu mümkün kılan Zapier’le de ortak çalışabilmektedir.</p>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <img alt="alt text" src={eManagement9} />
                                    <h3>Düzenli Güncellemeler &<br />Özel Talepler</h3>
                                    <p>Sendy, ihtiyaçlar doğrultusunda düzenli olarak güncellenir ve taleplere uygun geliştirmeler gerçekleştirir. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="email-marketing_testimonials">
                    <Testimonial />
                </div>

                <div className="hiring" style={{ padding: '50px 0px 0px' }}>
                    <img class="lines" alt="alt text" src={lines} />
                    <h2>Sıkça Sorulan Sorular</h2>
                    <SSS />
                </div>

                <div className="container manage-google ">
                    <h2 style={{ marginTop: 20 }}>E-posta pazarlama kampanya <br /> yönetimi için neden bizi seçmelisiniz?</h2>
                    <p className="manage-google_p">Hedef kitlenizle markanız adına direkt iletişim kuruyor, <br />etkili e-posta bültenleriyle markanızı ön plana çıkarıyoruz.</p>
                    <div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={balance} />
                            <div>
                                <h3>İleri Seviyede Uzmanlarla Çalışma</h3>
                                <p>E-posta pazarlama çalışmalarını stajyer veya az deneyimli kişiler değil, alanında uzman bir ekip yönetir.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={achievement} />
                            <div>
                                <h3>Değer Oluşturma</h3>
                                <p>Yatırım geri dönüşünüzü artırmak için yüksek etkili çalışmaya odaklanır ve bu hedefe yönelik belirlediğimiz KPI’ları sürekli olarak ölçümleriz.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={videoSearch} />
                            <div>
                                <h3>Etkili İletişim Yöntemleri</h3>
                                <p>E-posta pazarlama kampanyalarının performansını gözden geçirmek ve yeni fikirleri tartışmak için sizinle düzenli olarak  görüşmeler gerçekleştiririz.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={cv} />
                            <div>
                                <h3>Size Özel Rapor</h3>
                                <p>Tüm performans göstergelerinizin sonuçlarını ve markanız için en önemli istatistikleri, düzenli olarak rapor halinde sunarız.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={maleMan} />
                            <div>
                                <h3>Sendy Hizmet Sağlayıcı</h3>
                                <p>Birçok kullanışlı özelliğe sahip Sendy hizmet sağlayıcısı ile e-posta kampanyalarınızın performansını gerçek zamanlı olarak analiz edebiliyor ve optimize edebiliyoruz.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={business} />
                            <div>
                                <h3>Uygun Fiyatlandırmalar</h3>
                                <p>Etkili ve sonuca ulaşan e-posta pazarlama kampanyaları için yüksek ücretler ödemek zorunda kalmazsınız.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="team" style={{ padding: 0 }}>
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Size yardımcı olabilecek <br />e-posta pazarlama uzmanlarımız. </h2>
                    <Teams />
                </div>

                <div className="check-modal">
                    <span onClick={(e) => this.closeModal()}>X</span>
                    <h2 className="form-head">Formu doldurun.</h2>                       <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                    <div className="check-modal_container">
                        <form onSubmit={this.handleSubmit} >
                            <input type="text" name="text_204202" id="site" required  placeholder="Websitenizi girin." />
                            <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
                            <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="E-posta adresinizi girin." />
                            <button  >GÖNDER</button>
                            <div className="status"></div>
                        </form>
                        <img alt="alt text" src={checkModal} />
                    </div>
                </div>

            </Layout>


        )
    }
}
export default EmailMarketing

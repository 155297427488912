import React, { Component } from "react"
import InputRange from 'react-input-range';

import "react-input-range/lib/css/index.css"

class Range extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 10000,
      mailchimp: 0.03,
      cmonitor: 0.01
    };
    this.defaults = {
      mailchimp: 0.03,
      cmonitor: 5.00,
      sendin: 600,
      amazon: 200
    }

  }
  numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  }

  hesapM(valueX) {
    if (valueX <= 5000) {
      return valueX * 0.03;
    } else {
      let topla = valueX / 50.008 + 0.02;
      return topla.toFixed(2)
    }
  }
  hesapC(valueZ) {
    const x = this.defaults.cmonitor;
    return x + valueZ * 0.01;
  }
  hesapA(valueY) {
    let result = valueY * 0.005;
    return result.toFixed(2);
  }

  render() {
    return (
      <div className="col-md-6">
        <h3>E-posta Gönderim Maliyet Hesaplama</h3>
        <div className="slider-container">
          <InputRange
            maxValue={500000}
            minValue={1}
            value={this.state.value}
            onChange={value => this.setState({ value })} />
        </div>
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col" style={{ paddingRight: 155 }}>E-posta Servisi</th>
              <th scope="col">Her {this.numberWithCommas(this.state.value)} E-posta Maliyeti</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Mailchimp</td>
              <td>${this.hesapM(this.state.value)}</td>
            </tr>
            <tr>
              <td>Campaign Monitor</td>
              <td>${this.hesapC(this.state.value)}</td>
            </tr>
            <tr>
              <td>Amazon SES (Flatartmail)</td>
              <td>${this.hesapA(this.state.value)}</td>
            </tr>
          </tbody>
        </table>
        <p className="result"> E-posta gönderim maliyetinden tasarruf edilen tutar: <span>${this.numberWithCommas(this.hesapM(this.state.value) - this.hesapA(this.state.value))}</span></p>
      </div>



    );
  }
}


export default Range
